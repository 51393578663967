import { useEffect, useRef } from "react";

type WebglEnv = {
  gl: WebGL2RenderingContext; // This is an instance of WebGL2RenderingContext, not a string value
  internalFormat:
    | "R32F" // red channel, 32-bit floating point
    | "RGBA32F" // red, green, blue, alpha channels, 32-bit floating point
    | "R8" // red channel, 8-bit unsigned integer
    | "R8_SNORM" // red channel, 8-bit signed normalized integer
    | "R16F" // red channel, 16-bit floating point
    | "R16I" // red channel, 16-bit signed integer
    | "R16UI" // red channel, 16-bit unsigned integer
    | "R32I" // red channel, 32-bit signed integer
    | "R32UI" // red channel, 32-bit unsigned integer
    | "RG8" // red, green channels, 8-bit unsigned integer
    | "RG8_SNORM" // red, green channels, 8-bit signed normalized integer
    | "RG16F" // red, green channels, 16-bit floating point
    | "RG16I" // red, green channels, 16-bit signed integer
    | "RG16UI" // red, green channels, 16-bit unsigned integer
    | "RG32F" // red, green channels, 32-bit floating point
    | "RG32I" // red, green channels, 32-bit signed integer
    | "RG32UI" // red, green channels, 32-bit unsigned integer
    | "RGB8" // red, green, blue channels, 8-bit unsigned integer
    | "RGB8_SNORM" // red, green, blue channels, 8-bit signed normalized integer
    | "RGB16F" // red, green, blue channels, 16-bit floating point
    | "RGB16I" // red, green, blue channels, 16-bit signed integer
    | "RGB16UI" // red, green, blue channels, 16-bit unsigned integer
    | "RGB32F" // red, green, blue channels, 32-bit floating point
    | "RGB32I" // red, green, blue channels, 32-bit signed integer
    | "RGB32UI" // red, green, blue channels, 32-bit unsigned integer
    | "RGBA8" // red, green, blue, alpha channels, 8-bit unsigned integer
    | "RGBA8_SNORM" // red, green, blue, alpha channels, 8-bit signed normalized integer
    | "RGBA16F" // red, green, blue, alpha channels, 16-bit floating point
    | "RGBA16I" // red, green, blue, alpha channels, 16-bit signed integer
    | "RGBA16UI" // red, green, blue, alpha channels, 16-bit unsigned integer
    | "RGBA32I" // red, green, blue, alpha channels, 32-bit signed integer
    | "RGBA32UI"; // red, green, blue, alpha channels, 32-bit unsigned integer
  format:
    | "RED" // red channel
    | "RGBA" // red, green, blue, alpha channels
    | "RG" // red, green channels
    | "RGB" // red, green, blue channels
    | "RGBA_INTEGER" // red, green, blue, alpha channels
    | "RED_INTEGER" // red channel
    | "RG_INTEGER" // red, green channels
    | "RGB_INTEGER"; // red, green, blue channels
  type:
    | "FLOAT" // 32-bit floating point
    | "UNSIGNED_BYTE" // 8-bit unsigned integer
    | "BYTE" // 8-bit signed integer
    | "SHORT" // 16-bit signed integer
    | "UNSIGNED_SHORT" // 16-bit unsigned integer
    | "INT" // 32-bit signed integer
    | "UNSIGNED_INT" // 32-bit unsigned integer
    | "HALF_FLOAT"; // 16-bit floating point
};

function webglErrorCodeToString(code: number) {
  for (const key in WebGL2RenderingContext) {
    // @ts-ignore
    if (WebGL2RenderingContext[key] === code) {
      return key;
    }
  }
  return "UNKNOWN_ERROR";
}

function webglError(env: WebglEnv) {
  const error = env.gl.getError();
  if (error !== env.gl.NO_ERROR) {
    console.error("WebGL error:", error, webglErrorCodeToString(error));
  }
}

function createQuadAcrossCanvas(env: WebglEnv) {
  const { gl } = env;
  const positionAttributeLocation = gl.getAttribLocation(
    gl.getParameter(gl.CURRENT_PROGRAM),
    "a_position"
  );
  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  const positions = [-1, -1, 1, -1, -1, 1, -1, 1, 1, -1, 1, 1];
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

  const vao = gl.createVertexArray();
  gl.bindVertexArray(vao);
  gl.enableVertexAttribArray(positionAttributeLocation);
  const size = 2;
  const type = gl.FLOAT;
  const normalize = false;
  const stride = 0;
  const offset = 0;
  gl.vertexAttribPointer(
    positionAttributeLocation,
    size,
    type,
    normalize,
    stride,
    offset
  );
}

function createShader(
  env: WebglEnv,
  type: number,
  source: string
): WebGLShader {
  const { gl } = env;
  const shader = gl.createShader(type);
  if (!shader) throw new Error("Shader is null");
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  if (!success) {
    console.log(gl.getShaderInfoLog(shader));
    gl.deleteShader(shader);
    throw new Error("Shader compilation failed");
  }
  return shader;
}

function createAndUseProgram(
  env: WebglEnv,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader
) {
  const { gl } = env;
  const program = gl.createProgram();
  if (!program) throw new Error("Program is null");
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  const success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if (!success) {
    console.log(gl.getProgramInfoLog(program));
    gl.deleteProgram(program);
    throw new Error("Program linking failed");
  }
  gl.useProgram(program);
  return program;
}

function require_EXT_color_buffer_float(env: WebglEnv) {
  const ext = env.gl.getExtension("EXT_color_buffer_float");
  if (!ext) throw new Error("EXT_color_buffer_float is not available");
}

function defaultTexParameteri(env: WebglEnv) {
  const { gl } = env;
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
}

function createEmptyTexture(
  env: WebglEnv,
  width: number,
  height: number,
  unit: number
) {
  const { gl, internalFormat, format, type } = env;
  require_EXT_color_buffer_float(env);
  const tex = gl.createTexture();
  if (!tex) throw new Error("Texture is null");
  gl.activeTexture(gl.TEXTURE0 + unit);
  gl.bindTexture(gl.TEXTURE_2D, tex);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl[internalFormat],
    width,
    height,
    0,
    gl[format],
    gl[type],
    null
  );
  defaultTexParameteri(env);
  webglError(env);
  return tex;
}

function createTextureFromBuffer(
  env: WebglEnv,
  buffer: WebGLBuffer,
  width: number,
  height: number,
  unit: number
) {
  const { gl, internalFormat, format, type } = env;
  require_EXT_color_buffer_float(env);
  const tex = gl.createTexture();
  if (!tex) throw new Error("Texture is null");
  gl.activeTexture(gl.TEXTURE0 + unit);
  gl.bindTexture(gl.TEXTURE_2D, tex);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl[internalFormat],
    width,
    height,
    0,
    gl[format],
    gl[type],
    null
  );
  gl.bindBuffer(gl.PIXEL_UNPACK_BUFFER, buffer);
  gl.texSubImage2D(
    gl.TEXTURE_2D,
    0,
    0,
    0,
    width,
    height,
    gl[format],
    gl[type],
    0
  );
  gl.bindBuffer(gl.PIXEL_UNPACK_BUFFER, null);
  defaultTexParameteri(env);
  webglError(env);
  return tex;
}

function createFramebuffer(env: WebglEnv, tex: WebGLTexture) {
  const { gl } = env;
  const fb = gl.createFramebuffer();
  if (!fb) throw new Error("Framebuffer is null");
  gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
  gl.framebufferTexture2D(
    gl.FRAMEBUFFER,
    gl.COLOR_ATTACHMENT0,
    gl.TEXTURE_2D,
    tex,
    0
  );
  if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE)
    throw new Error("Framebuffer is not complete");
  return fb;
}

function assignTextureUniformLocation(
  env: WebglEnv,
  program: WebGLProgram,
  name: string,
  textureUnit: number
) {
  const { gl } = env;
  const location = gl.getUniformLocation(program, name);
  if (!location) throw new Error(`"${name}" uniform location is null`);
  gl.uniform1i(location, textureUnit);
}

function render(env: WebglEnv, outputWidth: number, outputHeight: number) {
  const { gl } = env;
  gl.viewport(0, 0, outputWidth, outputHeight);
  gl.clear(gl.COLOR_BUFFER_BIT);
  gl.drawArrays(gl.TRIANGLES, 0, 6);
}

function createBufferFromArray(env: WebglEnv, data: number[]) {
  const { gl } = env;
  const buffer = gl.createBuffer();
  if (!buffer) throw new Error("Buffer is null");
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(data), gl.STATIC_DRAW);
  return buffer;
}

function readBufferToArray(env: WebglEnv, buffer: WebGLBuffer, length: number) {
  const { gl } = env;
  const data = new Float32Array(length);
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.getBufferSubData(gl.ARRAY_BUFFER, 0, data);
  return data;
}

function deleteGLObjects(
  env: WebglEnv,
  objects: (WebGLBuffer | WebGLTexture)[]
) {
  const { gl } = env;
  for (const object of objects) {
    if (object instanceof WebGLBuffer) {
      gl.deleteBuffer(object);
    } else if (object instanceof WebGLTexture) {
      gl.deleteTexture(object);
    }
  }
}

function loadTextureDataToBuffer(
  env: WebglEnv,
  tex: WebGLTexture,
  width: number,
  height: number
) {
  const { gl, format, type } = env;
  const buffer = gl.createBuffer();
  if (!buffer) throw new Error("Buffer is null");
  gl.bindBuffer(gl.PIXEL_PACK_BUFFER, buffer);
  gl.bufferData(gl.PIXEL_PACK_BUFFER, width * height * 4, gl.STREAM_READ); // 4 bytes per pixel
  gl.bindFramebuffer(gl.FRAMEBUFFER, gl.createFramebuffer());
  gl.framebufferTexture2D(
    gl.FRAMEBUFFER,
    gl.COLOR_ATTACHMENT0,
    gl.TEXTURE_2D,
    tex,
    0
  );
  gl.readPixels(0, 0, width, height, gl[format], gl[type], 0);
  gl.bindBuffer(gl.PIXEL_PACK_BUFFER, null);
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  return buffer;
}

function main(canvas: HTMLCanvasElement) {
  const width = 5;
  const height = 5;
  const a = Array.from({ length: width * height }, (_, i) => i + 1);
  const b = Array.from({ length: width * height }, (_, i) => (i + 1) * 10);
  const c = Array.from({ length: width * height }, (_, i) => (i + 1) * 100);

  const gl = canvas.getContext("webgl2");
  if (!gl) throw new Error("WebGL2 is not supported");

  const env: WebglEnv = {
    gl,
    internalFormat: "R32F",
    format: "RED",
    type: "FLOAT",
  };

  const vertexShaderSource = `#version 300 es
in vec4 a_position;
void main() {
  gl_Position = a_position;
}`;

  // shader that sums a_values and b_values and returns the result
  const fragmentShaderSource = `#version 300 es
precision mediump float;
uniform sampler2D a_values;
uniform sampler2D b_values;
out vec4 outColor;

void main() {
  float a = texelFetch(a_values, ivec2(gl_FragCoord.xy), 0).r;
  float b = texelFetch(b_values, ivec2(gl_FragCoord.xy), 0).r;
  outColor = vec4(a + b);
}
`;

  // create shaders and link them to a program
  const vertexShader = createShader(env, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = createShader(
    env,
    gl.FRAGMENT_SHADER,
    fragmentShaderSource
  );
  const program = createAndUseProgram(env, vertexShader, fragmentShader);

  createQuadAcrossCanvas(env);

  // calculate the sum of a and b into result1
  const aBuffer = createBufferFromArray(env, a);
  const bBuffer = createBufferFromArray(env, b);

  const texA = createTextureFromBuffer(env, aBuffer, width, height, 0);
  const texB = createTextureFromBuffer(env, bBuffer, width, height, 1);
  assignTextureUniformLocation(env, program, "a_values", 0); // a
  assignTextureUniformLocation(env, program, "b_values", 1); // b

  const result1Tex = createEmptyTexture(env, width, height, 2);
  createFramebuffer(env, result1Tex);
  render(env, width, height);
  const result1Buffer = loadTextureDataToBuffer(env, result1Tex, width, height);
  deleteGLObjects(env, [aBuffer, bBuffer, texA, texB, result1Tex]);

  // calculate the sum of result1 and c into result2
  const cBuffer = createBufferFromArray(env, c);

  const result1Tex2 = createTextureFromBuffer(
    env,
    result1Buffer,
    width,
    height,
    0
  );
  const cTex = createTextureFromBuffer(env, cBuffer, width, height, 1);
  assignTextureUniformLocation(env, program, "a_values", 0); // result1
  assignTextureUniformLocation(env, program, "b_values", 1); // c

  const result2 = createEmptyTexture(env, width, height, 2);
  createFramebuffer(env, result2);
  render(env, width, height);
  const result2Buffer = loadTextureDataToBuffer(env, result2, width, height);
  deleteGLObjects(env, [result1Buffer, cBuffer, result1Tex2, cTex, result2]);

  // read the result from the output texture
  const result = readBufferToArray(env, result2Buffer, width * height);
  console.log("Result:", result);
  deleteGLObjects(env, [result2Buffer]);

  webglError(env);
}

export default function SandboxWebgl() {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  useEffect(() => {
    if (!canvasRef.current) return;
    main(canvasRef.current);
  }, []);

  return (
    <div>
      <h1>Webgl Sandbox</h1>
      <canvas ref={canvasRef} width={400} height={400}></canvas>
    </div>
  );
}
