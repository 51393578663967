import { pastExperiences, personalInfo, socialMedia } from "../data";
import PerlinFlowBackground from "./PerlinFlowBackground";
import { PersonalInfoCard, SocialMediaCard, ExperienceCard } from "./Cards";
import { useState } from "react";
import { ExperimentsGallery } from "./ExperimentsGallery";
import { FamiliarTechnologies } from "./FamiliarTechnologies";

const title = (text: string) => (
  <h2
    className="text-xl font-bold mb-2 bg-gray-200 px-1 w-max rounded-lg break-words max-w-full"
  >
    {text}
  </h2>
);

const section = (children: React.ReactNode) => (
  <div className="pt-8">{children}</div>
);

export default function Landing() {
  const [clearBackground, setClearBackground] = useState(false);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="fixed top-0 left-0 w-full h-full z-0 bg-gray-200">
        <PerlinFlowBackground
          clear={clearBackground}
          setClear={setClearBackground}
        />
      </div>
      {/* <button
        className="fixed right-4 bottom-4 px-4 py-2 rounded-lg shadow-md z-20"
        onClick={() => setClearBackground(true)}
      >
        New 🖼️
      </button> */}

      <div className="relative z-10 max-w-5xl mx-auto px-4 sm:px-12 lg:px-20 pb-8">
        {section(<PersonalInfoCard info={personalInfo} />)}

        {section(
          <>
            {title("Contact me at:")}
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
              {socialMedia.map((media) => (
                <SocialMediaCard key={media.name} media={media} />
              ))}
            </div>
          </>
        )}

        {section(
          <>
            {title("Check out some experiments I like doing on my free time:")}
            <ExperimentsGallery />
          </>
        )}

        {section(
          <>
            {title("My past professional experiences:")}
            <div className="space-y-4">
              {pastExperiences.map((experience) => (
                <ExperienceCard
                  key={experience.company_name}
                  experience={experience}
                />
              ))}
            </div>
          </>
        )}

        {section(
          <>
            {title("Technologies I'm familiar with:")}
            <FamiliarTechnologies />
          </>
        )}

        {section(
          <>
            {title(
              "If you haven't already, check out some experiments I like doing on my free time:"
            )}
            <ExperimentsGallery />
          </>
        )}
      </div>
    </div>
  );
}
