export type PersonalInfo = {
  name: string;
  surname: string;
  image_path: string;
  title: string;
  location: string;
  description: string;
};

export enum WorkSchedule {
  FULL_TIME = "Full-time",
  PART_TIME = "Part-time",
}

export enum WorkLocation {
  REMOTE = "Remote",
  HYBRID = "Hybrid",
  ON_SITE = "On-site",
}

export enum ClientScope {
  LOCAL = "Local Clients",
  GLOBAL = "Global Clients",
  BOTH = "Local and Global Clients",
}

export type Experience = {
  company_name: string;
  company_logo_path: string;
  role: string;
  start_date: Date;
  end_date: Date | null; // null means current job
  work_schedule: WorkSchedule;
  work_location: WorkLocation;
  client_scope: ClientScope;
  location: string;
  description: string;
};

export type SocialMedia = {
  name: string;
  url: string;
  logo_path: string;
};
