import { P5CanvasInstance } from "@p5-wrapper/react";

export function mouseInCanvas(p5: P5CanvasInstance<any>) {
  return (
    p5.mouseX >= 0 &&
    p5.mouseX <= p5.width &&
    p5.mouseY >= 0 &&
    p5.mouseY <= p5.height
  );
}

export function isPointWithinRadiusOfLine(
  x1: number, // line start x
  y1: number, // line start y
  x2: number, // line end x
  y2: number, // line end y
  p1: number, // point x
  p2: number, // point y
  r: number // radius
): boolean {
  // Calculate the length of the line segment (dx, dy)
  let dx = x2 - x1;
  let dy = y2 - y1;

  // Calculate the dot product
  let dot = ((p1 - x1) * dx + (p2 - y1) * dy) / (dx * dx + dy * dy);

  // Find the closest point on the line segment
  let closestX, closestY;
  if (dot < 0) {
    closestX = x1;
    closestY = y1;
  } else if (dot > 1) {
    closestX = x2;
    closestY = y2;
  } else {
    closestX = x1 + dot * dx;
    closestY = y1 + dot * dy;
  }

  // Calculate the distance from the point to the closest point on the line segment
  let distance = Math.sqrt(
    (p1 - closestX) * (p1 - closestX) + (p2 - closestY) * (p2 - closestY)
  );

  // Check if the distance is within the radius
  return distance <= r;
}
