export function reshapeList(data: any[], shape: number[]): any[] | number {
  if (shape.length === 0) {
    return data[0];
  }
  const length = shape[0];
  const restShape = shape.slice(1);
  let result = [];
  for (let i = 0; i < length; i++) {
    result.push(
      reshapeList(
        data.slice(
          i * restShape.reduce((a, b) => a * b, 1),
          (i + 1) * restShape.reduce((a, b) => a * b, 1)
        ),
        restShape
      )
    );
  }
  return result;
}

export function flattenList(data: any[]): number[] {
  const result: number[] = [];
  for (const value of data) {
    if (Array.isArray(value)) {
      result.push(...flattenList(value));
    } else {
      result.push(value);
    }
  }
  return result;
}

export function listShape(data: any[]): number[] {
  if (!Array.isArray(data)) {
    return [];
  }
  return [data.length, ...listShape(data[0])];
}
