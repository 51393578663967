export default function ExperimentExplanation() {
  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 my-6 space-y-3">
      <h1 className="text-3xl font-extrabold text-gray-900">
        Mnist Experiment
      </h1>
      <p>
        The experiment above demonstrates the intermediate activations and
        predictions of a small and efficient CNN, based on the number drawn on
        the canvas. The CNN was trained on a heavily augmented version of the
        MNIST dataset and achieves 99.5% accuracy on the test set from the
        original MNIST data.
      </p>

      <p>
        The model is purely sequential and without any linear layers. The model
        architecture is as follows:
      </p>

      <div>
        <h2 className="font-bold underline">Input</h2>
        <p>Shape: [batch_size, 1, 32, 32]</p>
      </div>

      <div>
        <h2 className="font-bold underline">First Block</h2>
        <p>Conv2d from 1 to 32 channels, kernel size 3, stride 1, padding 1</p>
        <p>BatchNorm2d across the 32 channels</p>
        <p>ReLU activation function</p>
        <p>MaxPool2d with kernel size 2</p>
      </div>

      <div>
        <h2 className="font-bold underline">Second Block</h2>
        <p>Conv2d from 32 to 64 channels, kernel size 3, stride 1, padding 1</p>
        <p>BatchNorm2d across the 64 channels</p>
        <p>ReLU activation function</p>
        <p>MaxPool2d with kernel size 2</p>
      </div>

      <div>
        <h2 className="font-bold underline">Third Block</h2>
        <p>
          Conv2d from 64 to 128 channels, kernel size 3, stride 1, padding 1
        </p>
        <p>BatchNorm2d across the 128 channels</p>
        <p>ReLU activation function</p>
        <p>MaxPool2d with kernel size 2</p>
      </div>

      <div>
        <h2 className="font-bold underline">Prediction Block</h2>
        <p>
          Conv2d from 128 to 10 channels, kernel size 1, stride 1, padding 0
        </p>
        <p>BatchNorm2d across the 10 channels</p>
        <p>AdaptiveAvgPool2d to 1x1</p>
        <p>Flatten to 10</p>
        <p>Softmax activation function</p>
      </div>

      <p>
        The input for the model is a gray scale image of size 32x32 because it
        is better to work with than the original 28x28 size from the MNIST
        dataset. All images were augmented and then slightly upsampled to 32x32.
      </p>

      <p>
        The model was trained using Pytorch with CUDA acceleration on 50000
        steps of batch size 64, here is the loss graph of the training in
        logaritmic scale:
      </p>
      <img src="/experiments/mnist/loss-graph.png" alt="Loss Graph" />
    </div>
  );
}
