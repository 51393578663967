import { useState, useRef, useCallback, useMemo } from "react";
import { interpolate } from "../utils/utils";

export type Stopwatch = {
  controls: {
    start: () => void;
    stop: () => void;
    reset: () => void;
  };
  render: () => JSX.Element;
};

export function useStopwatch(name: string): Stopwatch {
  const [time, setTime] = useState(0);
  const startTime = useRef<number | null>(null);

  const start = useCallback(() => {
    startTime.current = performance.now();
  }, []);
  const stop = useCallback(() => {
    if (startTime.current === null) throw new Error("Stopwatch not started");
    const newTime = performance.now() - startTime.current;
    startTime.current = null;
    setTime((prev) => interpolate(prev, newTime, 0.1));
  }, []);
  const reset = useCallback(() => {
    startTime.current = null;
    setTime(0);
  }, []);
  const render = useCallback(() => {
    return (
      <p className="text-xs">
        {name}: <b>{time.toFixed(2)}ms</b>
      </p>
    );
  }, [name, time]);
  const controls = useMemo(
    () => ({ start, stop, reset }),
    [start, stop, reset]
  );

  return {
    controls,
    render,
  };
}
