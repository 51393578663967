import { maxTextureSize } from "../webgl/webgl";
import {
  createTextureFromBuffer,
  assignTextureUniformLocation,
} from "../webgl/webglLowLevel";
import { Tensor } from "./tensor";

const webgl = {
  toTextureAtUniformLocation(
    this: Tensor,
    program: WebGLProgram,
    uniformLocation: string,
    textureUnit: number
  ) {
    if (this.webglData) {
      this.freeTexture(); // free previous texture if exists

      const textureWidth = Math.min(maxTextureSize, this.size);
      const textureHeight = Math.ceil(this.size / textureWidth);
      this.webglData.texture = createTextureFromBuffer(
        this.webglData.buffer,
        textureWidth,
        textureHeight,
        textureUnit
      );
      this.webglData.textureUnit = textureUnit;

      assignTextureUniformLocation(program, uniformLocation, textureUnit);
    } else
      throw new Error(
        "Tensor.toTextureAtUniformLocation not implemented for WASM tensors"
      );
  },
};

export default webgl;
