import {
  ClientScope,
  Experience,
  PersonalInfo,
  SocialMedia,
  WorkLocation,
  WorkSchedule,
} from "./types";

export const pastExperiences: Experience[] = [
  {
    company_name: "Blockful",
    company_logo_path: "assets/blockful-logo.jpeg",
    role: "Backend, Cloud Developer",
    start_date: new Date(2023, 0), // January 2023
    end_date: null,
    work_schedule: WorkSchedule.FULL_TIME,
    work_location: WorkLocation.REMOTE,
    client_scope: ClientScope.GLOBAL,
    location: "Florianópolis, Santa Catarina, Brazil",
    description:
      "Working with backend and cloud development, managing cloud infrastructure, developing backend services, utilizing generative AI to create customized marketplace experiences, and integrating blockchain with data processing.",
  },
  {
    company_name: "Rox Partner",
    company_logo_path: "assets/roxpartner-logo.jpeg",
    role: "Data Engineer, DataOps",
    start_date: new Date(2021, 8), // September 2021
    end_date: new Date(2023, 0), // January 2023
    work_schedule: WorkSchedule.FULL_TIME,
    work_location: WorkLocation.HYBRID,
    client_scope: ClientScope.BOTH,
    location: "São Paulo, São Paulo, Brazil",
    description:
      "Worked with data engineering and dataops at national big retail and bank companies, managing data pipelines, data lakes, and data warehouses, also worked with data quality and data governance.",
  },
  {
    company_name: "Partnr",
    company_logo_path: "assets/partnr-logo.jpeg",
    role: "Software Developer, Big Data Scientist",
    start_date: new Date(2020, 7), // August 2020
    end_date: new Date(2021, 8), // September 2021
    work_schedule: WorkSchedule.FULL_TIME,
    work_location: WorkLocation.REMOTE,
    client_scope: ClientScope.LOCAL,
    location: "Florianópolis, Santa Catarina, Brazil",
    description:
      "Worked on multiple fronts, from developing servers, to managing cloud and database infrastructure, worked with AI using state-of-the-art language models at the time to extract and parse data for product development.",
  },
];

export const socialMedia: SocialMedia[] = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/maicon-moreira-38ab691a4/",
    logo_path: "assets/linkedin-logo.webp",
  },
  {
    name: "X (Twitter)",
    url: "https://twitter.com/maiconmoreira42",
    logo_path: "assets/x-logo.webp",
  },
  {
    name: "GitHub",
    url: "https://github.com/Maicon-Moreira",
    logo_path: "assets/github-logo.png",
  },
  {
    name: "Polywork",
    url: "https://www.polywork.com/maicon",
    logo_path: "assets/polywork-logo.png",
  },
];

export const personalInfo: PersonalInfo = {
  name: "Maicon",
  surname: "Moreira",
  image_path: "assets/maicon.jpg",
  title: "Data/AI/ML lover",
  location: "Santa Catarina, Brazil",
  description:
    "I'm a software developer with a focus on backend, cloud, and data engineering, I love to work with data and AI, I'm always looking for new challenges and opportunities to learn and grow.",
};

export enum ScreenSizes {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  "2XL" = 1536,
}
