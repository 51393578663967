import { useState } from "react";
import { Card } from "./Cards";
import { useWindowWidth } from "@react-hook/window-size";
import { ScreenSizes } from "../data";

const ExperimentCard = (props: {
  name: string;
  description: string[];
  link: string;
  image: string;
  style?: React.CSSProperties;
}) => (
  <div
    onClick={() => (window.location.href = props.link)}
    className="cursor-pointer flex flex-col w-full bg-white rounded-lg sm:flex-row"
    style={props.style}
  >
    <img
      src={props.image}
      alt={props.name}
      className="rounded-lg w-full sm:max-w-48"
    />
    <div className="space-y-2 p-4">
      <h3 className="font-bold text-lg">{props.name}</h3>
      {props.description.map((desc) => (
        <p key={desc} className="text-sm text-gray-600">
          {desc}
        </p>
      ))}
    </div>
  </div>
);

const experiments = [
  {
    name: "Variational Autoencoder Visualization",
    description: [
      "Modify the latent space of a VAE model and visualize how the latent transforms through multiple layers into a generated random digit image from the MNIST dataset.",
      "The VAE model was trained using Pytorch with CUDA acceleration and the inference on web is done using a custom built WebAssembly/WebGL backend for tensor operations.",
    ],
    link: "/vae",
    image: "experiments/vae/banner.png",
  },
  {
    name: "MNIST Digit Classification",
    description: [
      "Draw a digit and see how a convolutional neural network classifies it.",
      "The CNN model was trained using Pytorch with CUDA acceleration and the inference on web is done using ONNX.js with some workarounds to make it possible to capture intermediate activations.",
    ],
    link: "/mnist",
    image: "experiments/mnist/banner.png",
  },
];

export const ExperimentsGallery = () => {
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [cardOpacity, setCardOpacity] = useState(1);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < ScreenSizes.SM;
  const animationDuration = 400;

  const leftButton = (
    <button
      onClick={() => {
        setCardOpacity(0);
        setTimeout(() => {
          setGalleryIndex(
            (galleryIndex - 1 + experiments.length) % experiments.length
          );
          setCardOpacity(1);
        }, animationDuration);
      }}
    >
      <p className="text-4xl text-right">👈</p>
      <p className="text-xs text-gray-600 text-right">Previous</p>
    </button>
  );

  const rightButton = (
    <button
      onClick={() => {
        setCardOpacity(0);
        setTimeout(() => {
          setGalleryIndex((galleryIndex + 1) % experiments.length);
          setCardOpacity(1);
        }, animationDuration);
      }}
    >
      <p className="text-4xl text-left">👉</p>
      <p className="text-xs text-gray-600 text-left">Next</p>
    </button>
  );

  return (
    <Card>
      <div className="flex flex-row items-center space-x-4 p-4">
        {!isMobile && leftButton}
        <ExperimentCard
          {...experiments[galleryIndex]}
          style={{
            opacity: cardOpacity,
            transition: `opacity ${animationDuration}ms`,
          }}
        />
        {!isMobile && rightButton}
      </div>
      {isMobile && (
        <div className="flex flex-row justify-center space-x-32 pb-4">
          {leftButton}
          {rightButton}
        </div>
      )}
    </Card>
  );
};
