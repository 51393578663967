export default function Toggle({
  options,
  selected,
  setSelected,
}: {
  options: [string, string];
  selected: string;
  setSelected: (selected: string) => void;
}) {
  const otherOption = selected === options[0] ? options[1] : options[0];
  const optionIndex = options.indexOf(selected);

  return (
    <div
      className="relative flex flex-row bg-orange-300 rounded overflow-hidden cursor-pointer w-32"
      onClick={() => setSelected(otherOption)}
      style={{ transition: "transform 0.3s" }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
      }}
    >
      <div
        className="absolute transition-transform duration-300 w-1/2 h-full top-0 left-0 p-0.5"
        style={{
          transform: `translateX(${optionIndex * 100}%)`,
        }}
      >
        <div className="bg-orange-100 w-full h-full rounded"></div>
      </div>
      {options.map((option) => (
        <button
          key={option}
          className="relative font-bold p-1 w-1/2 text-center text-sm"
        >
          {option}
        </button>
      ))}
    </div>
  );
}
