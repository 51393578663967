import { PersonalInfo, SocialMedia, Experience } from "../types";

export function Card({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`bg-white bg-opacity-60 backdrop-filter backdrop-blur-md shadow-md rounded-lg ${className}`}
    >
      {children}
    </div>
  );
}

export function PersonalInfoCard({ info }: { info: PersonalInfo }) {
  return (
    <Card className="space-y-2 p-4">
      <div className="flex items-center">
        <img
          src={info.image_path}
          alt={info.name}
          className="w-24 h-24 rounded-full mr-4"
        />
        <div>
          <h2 className="font-bold text-3xl mb-2">
            {info.name} {info.surname}
          </h2>
          <p>{info.title}</p>
        </div>
      </div>
      <p className="underline">{info.location}</p>
      <p>{info.description}</p>
    </Card>
  );
}

export function SocialMediaCard({ media }: { media: SocialMedia }) {
  return (
    <a
      href={media.url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-500 hover:text-blue-700"
    >
      <Card className="flex flex-row items-center space-x-4 p-4">
        <img src={media.logo_path} alt={media.name} className="w-8 h-8" />
        <p>{media.name}</p>
      </Card>
    </a>
  );
}

export function ExperienceCard({ experience }: { experience: Experience }) {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
  };

  return (
    <Card className="flex flex-col md:flex-row items-start md:items-center p-4">
      <img
        src={experience.company_logo_path}
        alt={experience.company_name}
        className="w-24 h-24 rounded-full md:mb-0 md:mr-6"
      />
      <div className="flex flex-col space-y-1">
        <h2 className="font-bold text-2xl">{experience.company_name}</h2>
        <p className="underline">{experience.role}</p>
        <p>
          {experience.start_date.toLocaleDateString(undefined, options)} -{" "}
          {experience.end_date
            ? experience.end_date.toLocaleDateString(undefined, options)
            : "Present"}{" "}
          ({experience.work_schedule} / {experience.work_location})
        </p>
        <p className="underline">
          {experience.location} | {experience.client_scope}
        </p>
        <p>{experience.description}</p>
      </div>
    </Card>
  );
}
