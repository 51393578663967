// technologies familiar with
// - python
// - javascript
// - c++
// - rust
// - golang
// - node.js
// - typescript
// - react
// - next.js
// - tailwindcss
// - html
// - css
// - git
// - docker
// - kubernetes
// - aws
// - aws lambda
// - aws s3
// - aws ec2
// - aws rds
// - aws dynamodb
// - gcp
// - gcp cloud functions
// - gcp cloud storage
// - gcp compute engine
// - gcp sql
// - azure
// - azure functions
// - azure blob storage
// - azure vm
// - azure sql
// - azure devops
// - azure resource manager
// - terraform
// - gitlab
// - github
// - ci/cd
// - cloudera
// - tensorflow
// - pytorch
// - inner workings of neural networks such as backpropagation, gradient descent, etc
// - computer vision
// - natural language processing
// - generative adversarial networks
// - variational autoencoders
// - convolutional neural networks
// - recurrent neural networks/lstms/grus
// - transformers
// - bert architecture
// - gpt architecture
// - attention/self-attention mechanisms
// - reinforcement learning/q-learning/deep q-learning/dqn/ddpg/ppo
// - actor-critic methods
// - policy gradient methods
// - onnx
// - onnx.js
// - wasm
// - webgl
// - opencv
// - llama.cpp
// - llama-cpp-python
// - ollama
// - sentiment analysis
// - text generation
// - image generation
// - image classification
// - object detection
// - image segmentation
// - style transfer
// - image super-resolution
// - face recognition
// - speech recognition
// - speech synthesis
// - audio classification
// - audio generation
// - embeddings
// - hugging face libraries, such as datasets, transformers, tokenizers, etc
// - fastapi
// - flask
// - django
// - trpc
// - sql
// - nosql
// - graphql
// - rest
// - apache spark
// - apache hadoop
// - pandas
// - numpy
// - scipy
// - matplotlib
// - seaborn
// - plotly
// - jupyter notebook

import { Card } from "./Cards";

// Programming Languages
// Python
// JavaScript
// C++
// Rust
// GoLang

// Frontend Development
// HTML
// CSS
// React
// Next.js
// TailwindCSS
// TypeScript

// Backend Development
// Node.js
// FastAPI
// Flask
// Django
// TRPC

// DevOps and Cloud Platforms
// Git
// Docker
// Kubernetes
// Terraform
// CI/CD
// GitLab
// GitHub
// AWS Services

// AWS
// AWS Lambda
// AWS S3
// AWS EC2
// AWS RDS
// AWS DynamoDB
// GCP Services

// GCP
// GCP Cloud Functions
// GCP Cloud Storage
// GCP Compute Engine
// GCP SQL
// Azure Services

// Azure
// Azure Functions
// Azure Blob Storage
// Azure VM
// Azure SQL
// Azure DevOps
// Azure Resource Manager
// Data Engineering
// SQL
// NoSQL
// GraphQL
// REST
// Apache Spark
// Apache Hadoop
// Cloudera

// Machine Learning Frameworks and Tools
// TensorFlow
// PyTorch
// ONNX
// ONNX.js
// OpenCV
// Llama.cpp
// Llama-cpp-python
// Ollama
// Hugging Face Libraries (Datasets, Transformers, Tokenizers, etc.)

// Machine Learning Concepts and Architectures
// Inner workings of neural networks (e.g., backpropagation, gradient descent)
// Generative Adversarial Networks
// Variational Autoencoders
// Convolutional Neural Networks
// Recurrent Neural Networks/LSTMs/GRUs
// Transformers
// BERT Architecture
// GPT Architecture
// Attention/Self-Attention Mechanisms
// Reinforcement Learning/Q-Learning/Deep Q-Learning/DQN/DDPG/PPO
// Actor-Critic Methods
// Policy Gradient Methods
// Data Science and Analytics
// Pandas
// NumPy
// SciPy
// Matplotlib
// Seaborn
// Plotly
// Jupyter Notebook
// Web and Mobile Technologies
// WebGL
// WASM
// Computer Vision and Image Processing
// Image Generation
// Image Classification
// Object Detection
// Image Segmentation
// Style Transfer
// Image Super-Resolution
// Face Recognition
// Natural Language Processing
// Sentiment Analysis
// Text Generation
// Audio Processing
// Speech Recognition
// Speech Synthesis
// Audio Classification
// Audio Generation
// Miscellaneous
// Embeddings

type Technology = {
  name: string;
  description: string;
};

type TechnologySection = {
  title: string;
  technologies: Technology[];
};

const familiarLanguages: TechnologySection = {
  title: "Languages",
  technologies: [
    {
      name: "Python",
      description:
        "Use it most for data science and machine learning and general scripting.",
    },
    {
      name: "JavaScript",
      description:
        "Rarely use it by itself as I prefer using the TypeScript superset.",
    },
    {
      name: "TypeScript",
      description:
        "Use it for all my JavaScript projects to add types and highly improve code quality.",
    },
    {
      name: "C++",
      description:
        "Use it for competitive programming and some high-performance computing tasks.",
    },
    {
      name: "Rust",
      description:
        "Don't use it much but I like its safety features, performance and wasm support.",
    },
    {
      name: "GoLang",
      description:
        "Don't use it much but I like its simplicity and performance.",
    },
  ],
};

const familiarFrontendTechnologies: TechnologySection = {
  title: "Web Technologies",
  technologies: [
    {
      name: "React",
      description: "Use it for building web applications.",
    },
    {
      name: "Next.js",
      description: "Use it for building server-side rendered web applications.",
    },
    {
      name: "TailwindCSS",
      description:
        "Love to use it for styling web applications, using in this exact website.",
    },
  ],
};

const familiarBackendTechnologies: TechnologySection = {
  title: "Backend Technologies",
  technologies: [
    {
      name: "Node.js",
      description:
        "Very useful in combination with TypeScript to build APIs and services.",
    },
    {
      name: "Express.js",
      description:
        "Love to use it for the simplicity and flexibility it provides.",
    },
    {
      name: "FastAPI",
      description: "Don't use it much but I like its ease of use and speed.",
    },
    {
      name: "Flask",
      description: "Don't use it much but I like its simplicity.",
    },
    {
      name: "Django",
      description:
        "Don't use it much but I like its batteries-included approach.",
    },
    {
      name: "TRPC",
      description:
        "Love to use it for building stable and scalable typescript APIs.",
    },
  ],
};

const familiarDevOpsTechnologies: TechnologySection = {
  title: "DevOps and Cloud Platforms",
  technologies: [
    {
      name: "Git",
      description: "Very familiar with it for version control.",
    },
    {
      name: "Docker",
      description: "Very familiar with it for containerization.",
    },
    {
      name: "Kubernetes",
      description: "Don't use it much but I like its scalability features.",
    },
    {
      name: "Terraform",
      description:
        "Love the approach Terraform takes for infrastructure as code.",
    },
    {
      name: "CI/CD",
      description:
        "Applied CI/CD pipelines in many different places, such as Github, Azure DevOps, GitLab, etc.",
    },
    {
      name: "GitLab",
      description: "Use it for CI/CD and version control.",
    },
    {
      name: "GitHub",
      description:
        "Version control platform that I use for most of my projects.",
    },
  ],
};

const familiarAWS: TechnologySection = {
  title: "AWS Services",
  technologies: [
    {
      name: "AWS Lambda",
      description: "Use it for serverless computing.",
    },
    {
      name: "AWS S3",
      description: "Use it for object storage.",
    },
    {
      name: "AWS EC2",
      description: "Use it for virtual servers.",
    },
    {
      name: "AWS RDS",
      description: "Use it for managed databases.",
    },
    {
      name: "AWS DynamoDB",
      description: "Don't use it much but I like its scalability.",
    },
  ],
};

const familiarGCP: TechnologySection = {
  title: "GCP Services",
  technologies: [
    {
      name: "GCP Cloud Storage",
      description: "Use it for object storage.",
    },
    {
      name: "GCP Compute Engine",
      description: "Use it for virtual servers.",
    },
    {
      name: "GCP SQL",
      description: "Use it for managed databases.",
    },
  ],
};

const familiarAzure: TechnologySection = {
  title: "Azure Services",
  technologies: [
    {
      name: "Azure Functions",
      description: "Use it for serverless computing.",
    },
    {
      name: "Azure Blob Storage",
      description: "Use it for object storage.",
    },
    {
      name: "Azure VM",
      description: "Use it for virtual servers.",
    },
    {
      name: "Azure SQL",
      description: "Use it for managed databases.",
    },
    {
      name: "Azure DevOps",
      description: "Use it for CI/CD pipelines.",
    },
    {
      name: "Azure Resource Manager",
      description: "Use it for managing Azure resources.",
    },
    {
      name: "Azure Data Factory",
      description: "Use it for data integration and ETL.",
    },
  ],
};

const familiarDataTechnologies: TechnologySection = {
  title: "Data Engineering",
  technologies: [
    {
      name: "SQL",
      description: "Standard for relational databases.",
    },
    {
      name: "NoSQL",
      description: "Use it for non-relational databases.",
    },
    {
      name: "GraphQL",
      description: "Don't use it much but I like its flexibility.",
    },
    {
      name: "REST",
      description: "Use it for building APIs.",
    },
    {
      name: "Apache Spark",
      description: "Use it for big data processing, mainly in Cloudera.",
    },
    {
      name: "Apache Hadoop",
      description:
        "Use it for distributed storage and processing, mainly in Cloudera.",
    },
  ],
};

const familiarMLFrameworks: TechnologySection = {
  title: "Machine Learning Frameworks and Tools",
  technologies: [
    {
      name: "TensorFlow",
      description: "Don't use it much anymore but I'm familiar with it.",
    },
    {
      name: "PyTorch",
      description: "Love to use it for its dynamic computation graph.",
    },
    {
      name: "ONNX",
      description: "Use it for interoperability between ML frameworks.",
    },
    {
      name: "ONNX.js",
      description: "Use it for running ONNX models in the browser.",
    },
    {
      name: "OpenCV",
      description: "Classic library for computer vision tasks.",
    },
    {
      name: "Llama.cpp",
      description: "Fantastic high performance backend to run LLMs.",
    },
    {
      name: "Llama-cpp-python",
      description: "Llama.cpp bindings for Python.",
    },
    {
      name: "Ollama",
      description:
        "Don't use it much but I like the simplicity layer it adds on top of llama.cpp.",
    },
    {
      name: "Hugging Face Libraries",
      description: "Use it for general NLP tasks.",
    },
  ],
};

const familiarMLConcepts: TechnologySection = {
  title: "Machine Learning Concepts and Architectures",
  technologies: [
    {
      name: "Inner workings of neural networks",
      description:
        "Understand the math behind backpropagation, gradient descent, etc.",
    },
    {
      name: "Generative Adversarial Networks",
      description: "Use it for generating images and other tasks.",
    },
    {
      name: "Variational Autoencoders",
      description: "Use it for compressing data into a latent space.",
    },
    {
      name: "Convolutional Neural Networks",
      description: "Use it for any image-related tasks.",
    },
    {
      name: "Recurrent Neural Networks/LSTMs/GRUs",
      description: "Use it for sequence prediction tasks.",
    },
    {
      name: "Transformers",
      description: "SOTA for NLP tasks.",
    },
    {
      name: "BERT Architecture",
      description: "Don't use it much anymore, newer models are better.",
    },
    {
      name: "GPT Architecture",
      description: "Use it for NLP tasks.",
    },
    {
      name: "Attention/Self-Attention Mechanisms",
      description: "Very interesting subject to dive into.",
    },
    {
      name: "Reinforcement Learning/Q-Learning/Deep Q-Learning/DQN/DDPG/PPO",
      description: "Use it for training agents to play games.",
    },
    {
      name: "Actor-Critic Methods",
      description: "Reinforcement learning methods.",
    },
    {
      name: "Policy Gradient Methods",
      description: "Reinforcement learning methods.",
    },
  ],
};

const familiarDataScienceTechnologies: TechnologySection = {
  title: "Data Science and Analytics",
  technologies: [
    {
      name: "Pandas",
      description: "Use it for data manipulation and analysis.",
    },
    {
      name: "NumPy",
      description: "Use it for numerical computing.",
    },
    {
      name: "SciPy",
      description: "Use it for scientific computing.",
    },
    {
      name: "Matplotlib",
      description: "Use it for data visualization.",
    },
    {
      name: "Seaborn",
      description: "Use it for statistical data visualization.",
    },
    {
      name: "Plotly",
      description: "Use it for interactive data visualization.",
    },
    {
      name: "Jupyter Notebook",
      description: "Use it for interactive computing.",
    },
  ],
};

const familiarWebTechnologies: TechnologySection = {
  title: "Web and Mobile Technologies",
  technologies: [
    {
      name: "WebGL",
      description: "Use it for 3D graphics on the web.",
    },
    {
      name: "WASM",
      description: "Use it for running C++ and Rust code in the browser.",
    },
  ],
};

const familiarCVTechnologies: TechnologySection = {
  title: "Computer Vision and Image Processing",
  technologies: [
    {
      name: "Image Generation",
      description:
        "Mainly through the use of GANs or newer diffusion models such as DALL-E or StableDiffusion.",
    },
    {
      name: "Image Classification",
      description:
        "Using either CNNs or newer models such as Vision Transformers.",
    },
    {
      name: "Object Detection",
      description: "Don't use it much but I like the YOLO architecture.",
    },
    {
      name: "Image Segmentation",
      description: "Don't use it much but I like the U-Net architecture.",
    },
    {
      name: "Style Transfer",
      description: "Very interesting subject to dive into.",
    },
    {
      name: "Image Super-Resolution",
      description: "Very useful application for CNNs.",
    },
    {
      name: "Face Recognition",
      description: "Don't use it much but I like the FaceNet architecture.",
    },
  ],
};

const familiarNLPTechnologies: TechnologySection = {
  title: "Natural Language Processing",
  technologies: [
    {
      name: "Sentiment Analysis",
      description: "Use mainly with hugging face models.",
    },
    {
      name: "Text Generation",
      description:
        "Use either with local modern LLMs or apis such as OpenAI's, Anthropic's, etc.",
    },
    {
      name: "Text similarity",
      description: "Using embeddings to find similarity between texts.",
    },
  ],
};

const familiarAudioTechnologies: TechnologySection = {
  title: "Audio Processing",
  technologies: [
    {
      name: "Speech Recognition",
      description:
        "Use either OpenAI's Whisper model or local models downloaded from Hugging Face.",
    },
    {
      name: "Speech Synthesis",
      description: "Use local models downloaded from Hugging Face.",
    },
    {
      name: "Audio Classification",
      description: "Don't use it much but I like the VGGish architecture.",
    },
    {
      name: "Audio Generation",
      description: "Don't use it much but I like the WaveNet architecture.",
    },
  ],
};

const technologies: TechnologySection[] = [
  familiarLanguages,
  familiarFrontendTechnologies,
  familiarBackendTechnologies,
  familiarDevOpsTechnologies,
  familiarAWS,
  familiarGCP,
  familiarAzure,
  familiarDataTechnologies,
  familiarMLFrameworks,
  familiarMLConcepts,
  familiarDataScienceTechnologies,
  familiarWebTechnologies,
  familiarCVTechnologies,
  familiarNLPTechnologies,
  familiarAudioTechnologies,
];

const TechnologyCard = ({ technology }: { technology: Technology }) => (
  <div className="bg-white shadow-md rounded-lg p-4">
    <h3 className="text-lg font-bold">{technology.name}</h3>
    <p className="text-gray-600">{technology.description}</p>
  </div>
);

const TechnologySectionCard = ({ section }: { section: TechnologySection }) => (
  <div className="space-y-2">
    <h2 className="text-xl font-bold">{section.title}</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {section.technologies.map((technology) => (
        <TechnologyCard key={technology.name} technology={technology} />
      ))}
    </div>
    <h3 className="font-bold">And many more...</h3>
  </div>
);

export const FamiliarTechnologies = () => (
  <Card className="p-4">
    <div className="space-y-4">
      {technologies.map((section) => (
        <TechnologySectionCard key={section.title} section={section} />
      ))}
    </div>
    <h3 className="mt-4">
      In the end, I am always adapting and learning new technologies and tools
      as the need arises and problems to be solved change, and that is what
      matters most.
    </h3>
  </Card>
);
