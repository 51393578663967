import React from "react";
import ReactDOM from "react-dom/client";
import Landing from "./Landing/Landing";
import SandboxP5js from "./Sandbox/SandboxP5js";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MnistExperiment from "./experiments/Mnist/Mnist";
import VAEExperiment from "./experiments/VAE/VAE";
import SandboxWasm from "./Sandbox/SandboxWasm";
import SandboxWebgl from "./Sandbox/SandboxWebgl";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/sandbox-p5js",
    element: <SandboxP5js />,
  },
  {
    path: "/sandbox-wasm",
    element: <SandboxWasm />,
  },
  {
    path: "/mnist",
    element: <MnistExperiment />,
  },
  {
    path: "/vae",
    element: <VAEExperiment />,
  },
  {
    path: "/webgl",
    element: <SandboxWebgl />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
