import { isInteger } from "../../utils/utils";

const WEBGL_LOW_LEVEL_DEBUG = true && process.env.NODE_ENV === "development";

if (WEBGL_LOW_LEVEL_DEBUG) {
  console.warn(
    "ATTENTION: WEBGL_LOW_LEVEL_DEBUG is enabled, disable for higher performance"
  );
}

type WebglConfig = {
  internalFormat:
    | "R32F" // red channel, 32-bit floating point
    | "RGBA32F" // red, green, blue, alpha channels, 32-bit floating point
    | "R8" // red channel, 8-bit unsigned integer
    | "R8_SNORM" // red channel, 8-bit signed normalized integer
    | "R16F" // red channel, 16-bit floating point
    | "R16I" // red channel, 16-bit signed integer
    | "R16UI" // red channel, 16-bit unsigned integer
    | "R32I" // red channel, 32-bit signed integer
    | "R32UI" // red channel, 32-bit unsigned integer
    | "RG8" // red, green channels, 8-bit unsigned integer
    | "RG8_SNORM" // red, green channels, 8-bit signed normalized integer
    | "RG16F" // red, green channels, 16-bit floating point
    | "RG16I" // red, green channels, 16-bit signed integer
    | "RG16UI" // red, green channels, 16-bit unsigned integer
    | "RG32F" // red, green channels, 32-bit floating point
    | "RG32I" // red, green channels, 32-bit signed integer
    | "RG32UI" // red, green channels, 32-bit unsigned integer
    | "RGB8" // red, green, blue channels, 8-bit unsigned integer
    | "RGB8_SNORM" // red, green, blue channels, 8-bit signed normalized integer
    | "RGB16F" // red, green, blue channels, 16-bit floating point
    | "RGB16I" // red, green, blue channels, 16-bit signed integer
    | "RGB16UI" // red, green, blue channels, 16-bit unsigned integer
    | "RGB32F" // red, green, blue channels, 32-bit floating point
    | "RGB32I" // red, green, blue channels, 32-bit signed integer
    | "RGB32UI" // red, green, blue channels, 32-bit unsigned integer
    | "RGBA8" // red, green, blue, alpha channels, 8-bit unsigned integer
    | "RGBA8_SNORM" // red, green, blue, alpha channels, 8-bit signed normalized integer
    | "RGBA16F" // red, green, blue, alpha channels, 16-bit floating point
    | "RGBA16I" // red, green, blue, alpha channels, 16-bit signed integer
    | "RGBA16UI" // red, green, blue, alpha channels, 16-bit unsigned integer
    | "RGBA32I" // red, green, blue, alpha channels, 32-bit signed integer
    | "RGBA32UI"; // red, green, blue, alpha channels, 32-bit unsigned integer
  format:
    | "RED" // red channel
    | "RGBA" // red, green, blue, alpha channels
    | "RG" // red, green channels
    | "RGB" // red, green, blue channels
    | "RGBA_INTEGER" // red, green, blue, alpha channels
    | "RED_INTEGER" // red channel
    | "RG_INTEGER" // red, green channels
    | "RGB_INTEGER"; // red, green, blue channels
  type:
    | "FLOAT" // 32-bit floating point
    | "UNSIGNED_BYTE" // 8-bit unsigned integer
    | "BYTE" // 8-bit signed integer
    | "SHORT" // 16-bit signed integer
    | "UNSIGNED_SHORT" // 16-bit unsigned integer
    | "INT" // 32-bit signed integer
    | "UNSIGNED_INT" // 32-bit unsigned integer
    | "HALF_FLOAT"; // 16-bit floating point
};

const typeSizesBytes = {
  FLOAT: 4,
  UNSIGNED_BYTE: 1,
  BYTE: 1,
  SHORT: 2,
  UNSIGNED_SHORT: 2,
  INT: 4,
  UNSIGNED_INT: 4,
  HALF_FLOAT: 2,
};

const canvas = document.createElement("canvas");
const gl = canvas.getContext("webgl2");
const webglConfig: WebglConfig = {
  internalFormat: "R32F",
  format: "RED",
  type: "FLOAT",
};
const elementByteSize = typeSizesBytes[webglConfig.type];

export function webglErrorCodeToString(code: number) {
  for (const key in WebGL2RenderingContext) {
    // @ts-ignore
    if (WebGL2RenderingContext[key] === code) {
      return key;
    }
  }
  return "UNKNOWN_ERROR";
}

export function webglError() {
  if (!gl) throw new Error("cannot call webglError without a WebGL context");
  const error = gl.getError();
  if (error !== gl.NO_ERROR) {
    console.error("WebGL error:", error, webglErrorCodeToString(error));
  }
}

export function createQuadAcrossCanvas() {
  if (!gl)
    throw new Error(
      "cannot call createQuadAcrossCanvas without a WebGL context"
    );
  const positionAttributeLocation = gl.getAttribLocation(
    gl.getParameter(gl.CURRENT_PROGRAM),
    "a_position"
  );
  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  const positions = [-1, -1, 1, -1, -1, 1, -1, 1, 1, -1, 1, 1];
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

  const vao = gl.createVertexArray();
  gl.bindVertexArray(vao);
  gl.enableVertexAttribArray(positionAttributeLocation);
  const size = 2;
  const type = gl.FLOAT;
  const normalize = false;
  const stride = 0;
  const offset = 0;
  gl.vertexAttribPointer(
    positionAttributeLocation,
    size,
    type,
    normalize,
    stride,
    offset
  );
}

function createShader(type: number, source: string): WebGLShader {
  if (!gl) throw new Error("cannot call createShader without a WebGL context");
  const shader = gl.createShader(type);
  if (!shader) throw new Error("Shader is null");
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  if (!success) {
    console.log(gl.getShaderInfoLog(shader));
    gl.deleteShader(shader);
    throw new Error("Shader compilation failed");
  }
  return shader;
}

export function createVertexShader(source: string) {
  if (!gl)
    throw new Error("cannot call createVertexShader without a WebGL context");
  return createShader(gl.VERTEX_SHADER, source);
}

export function createFragmentShader(source: string) {
  if (!gl)
    throw new Error("cannot call createFragmentShader without a WebGL context");
  return createShader(gl.FRAGMENT_SHADER, source);
}

export function createProgram(
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader
) {
  if (!gl) throw new Error("cannot call createProgram without a WebGL context");
  const program = gl.createProgram();
  if (!program) throw new Error("Program is null");
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  const success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if (!success) {
    console.log(gl.getProgramInfoLog(program));
    gl.deleteProgram(program);
    throw new Error("Program linking failed");
  }
  return program;
}

export function require_EXT_color_buffer_float() {
  if (!gl)
    throw new Error(
      "cannot call require_EXT_color_buffer_float without a WebGL context"
    );
  const ext = gl.getExtension("EXT_color_buffer_float");
  if (!ext) throw new Error("EXT_color_buffer_float is not available");
}

export function defaultTexParameteri() {
  if (!gl)
    throw new Error("cannot call defaultTexParameteri without a WebGL context");
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
}

export function createEmptyTexture(
  width: number,
  height: number,
  unit: number
) {
  if (!gl)
    throw new Error("cannot call createEmptyTexture without a WebGL context");
  require_EXT_color_buffer_float();
  const tex = gl.createTexture();
  if (!tex) throw new Error("Texture is null");
  gl.activeTexture(gl.TEXTURE0 + unit);
  gl.bindTexture(gl.TEXTURE_2D, tex);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl[webglConfig.internalFormat],
    width,
    height,
    0,
    gl[webglConfig.format],
    gl[webglConfig.type],
    null
  );
  defaultTexParameteri();
  webglError();
  return tex;
}

export function getBufferSizeBytes(buffer: WebGLBuffer) {
  if (!gl)
    throw new Error("cannot call getBufferSizes without a WebGL context");
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  const size = gl.getBufferParameter(gl.ARRAY_BUFFER, gl.BUFFER_SIZE);
  return size;
}

export function getBufferSize(buffer: WebGLBuffer) {
  return getBufferSizeBytes(buffer) / elementByteSize;
}

export function createTextureFromBuffer(
  buffer: WebGLBuffer,
  width: number,
  height: number,
  unit: number
) {
  if (!gl)
    throw new Error(
      "cannot call createTextureFromBuffer without a WebGL context"
    );

  if (WEBGL_LOW_LEVEL_DEBUG) {
    // make sure the buffer is the right size
    if (getBufferSize(buffer) !== width * height) {
      throw new Error(
        `Buffer size (${getBufferSize(
          buffer
        )}) does not match texture size (${width}x${height})`
      );
    }
  }
  require_EXT_color_buffer_float();
  const tex = gl.createTexture();
  if (!tex) throw new Error("Texture is null");
  gl.activeTexture(gl.TEXTURE0 + unit);
  gl.bindTexture(gl.TEXTURE_2D, tex);
  gl.texImage2D(
    gl.TEXTURE_2D, // target
    0, // mip level
    gl[webglConfig.internalFormat], // internal format
    width, // width
    height, // height
    0, // border
    gl[webglConfig.format], // format
    gl[webglConfig.type], // type
    null // data
  );
  gl.bindBuffer(gl.PIXEL_UNPACK_BUFFER, buffer);
  gl.texSubImage2D(
    gl.TEXTURE_2D, // target
    0, // mip level
    0, // x offset
    0, // y offset
    width, // width
    height, // height
    gl[webglConfig.format], // format
    gl[webglConfig.type], // type
    0 // offset
  );
  gl.bindBuffer(gl.PIXEL_UNPACK_BUFFER, null);
  defaultTexParameteri();
  webglError();
  return tex;
}

export function createFramebuffer(tex: WebGLTexture) {
  if (!gl)
    throw new Error("cannot call createFramebuffer without a WebGL context");
  const fb = gl.createFramebuffer();
  if (!fb) throw new Error("Framebuffer is null");
  gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
  gl.framebufferTexture2D(
    gl.FRAMEBUFFER,
    gl.COLOR_ATTACHMENT0,
    gl.TEXTURE_2D,
    tex,
    0
  );
  if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE)
    throw new Error("Framebuffer is not complete");
  return fb;
}

export function assignTextureUniformLocation(
  program: WebGLProgram,
  name: string,
  textureUnit: number
) {
  if (!gl)
    throw new Error(
      "cannot call assignTextureUniformLocation without a WebGL context"
    );
  const location = gl.getUniformLocation(program, name);
  if (!location) console.error(`"${name}" uniform location is null`);
  gl.uniform1i(location, textureUnit);
}

export function assignIntUniformLocation(
  program: WebGLProgram,
  name: string,
  value: number
) {
  if (!gl)
    throw new Error(
      "cannot call assignIntUniformLocation without a WebGL context"
    );
  if (WEBGL_LOW_LEVEL_DEBUG) {
    if (!isInteger(value)) {
      throw new Error(`Value ${value} is not an integer`);
    }
  }
  const location = gl.getUniformLocation(program, name);
  if (!location) console.error(`"${name}" uniform location is null`);
  gl.uniform1i(location, value);
}

export function assignFloatUniformLocation(
  program: WebGLProgram,
  name: string,
  value: number
) {
  if (!gl)
    throw new Error(
      "cannot call assignFloatUniformLocation without a WebGL context"
    );
  const location = gl.getUniformLocation(program, name);
  if (!location) console.error(`"${name}" uniform location is null`);
  gl.uniform1f(location, value);
}

export function render(outputWidth: number, outputHeight: number) {
  if (!gl) throw new Error("cannot call render without a WebGL context");
  gl.viewport(0, 0, outputWidth, outputHeight);
  gl.clear(gl.COLOR_BUFFER_BIT);
  gl.drawArrays(gl.TRIANGLES, 0, 6);
}

export function createBufferFromArray(data: Float32Array) {
  if (!gl)
    throw new Error(
      "cannot call createBufferFromArray without a WebGL context"
    );
  const buffer = gl.createBuffer();
  if (!buffer) throw new Error("Buffer is null");
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
  return buffer;
}

export function padBuffer(buffer: WebGLBuffer, size: number) {
  if (!gl) throw new Error("cannot call padBuffer without a WebGL context");
  const newSize = size * elementByteSize;
  const oldSize = getBufferSizeBytes(buffer);
  if (newSize === oldSize) return buffer;
  const newBuffer = gl.createBuffer();
  if (!newBuffer) throw new Error("Buffer is null");
  gl.bindBuffer(gl.COPY_READ_BUFFER, buffer);
  gl.bindBuffer(gl.COPY_WRITE_BUFFER, newBuffer);
  gl.bufferData(gl.COPY_WRITE_BUFFER, newSize, gl.STATIC_DRAW);
  gl.copyBufferSubData(
    gl.COPY_READ_BUFFER,
    gl.COPY_WRITE_BUFFER,
    0,
    0,
    oldSize
  );
  gl.bindBuffer(gl.COPY_READ_BUFFER, null);
  gl.bindBuffer(gl.COPY_WRITE_BUFFER, null);
  return newBuffer;
}

export function cloneBuffer(buffer: WebGLBuffer) {
  if (!gl) throw new Error("cannot call cloneBuffer without a WebGL context");
  const newBuffer = gl.createBuffer();
  if (!newBuffer) throw new Error("Buffer is null");
  gl.bindBuffer(gl.COPY_READ_BUFFER, buffer);
  const size = gl.getBufferParameter(gl.COPY_READ_BUFFER, gl.BUFFER_SIZE);
  gl.bindBuffer(gl.COPY_WRITE_BUFFER, newBuffer);
  gl.bufferData(gl.COPY_WRITE_BUFFER, size, gl.STATIC_DRAW);
  gl.copyBufferSubData(gl.COPY_READ_BUFFER, gl.COPY_WRITE_BUFFER, 0, 0, size);
  gl.bindBuffer(gl.COPY_READ_BUFFER, null);
  gl.bindBuffer(gl.COPY_WRITE_BUFFER, null);
  return newBuffer;
}

export function readBufferToArray(
  buffer: WebGLBuffer,
  float32array: Float32Array
) {
  if (!gl)
    throw new Error("cannot call readBufferToArray without a WebGL context");
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.getBufferSubData(gl.ARRAY_BUFFER, 0, float32array);
}

export function loadTextureDataToBuffer(
  tex: WebGLTexture,
  width: number,
  height: number
) {
  if (!gl)
    throw new Error(
      "cannot call loadTextureDataToBuffer without a WebGL context"
    );
  const buffer = gl.createBuffer();
  if (!buffer) throw new Error("Buffer is null");
  gl.bindBuffer(gl.PIXEL_PACK_BUFFER, buffer);
  gl.bufferData(gl.PIXEL_PACK_BUFFER, width * height * 4, gl.STREAM_READ); // 4 bytes per pixel
  gl.bindFramebuffer(gl.FRAMEBUFFER, gl.createFramebuffer());
  gl.framebufferTexture2D(
    gl.FRAMEBUFFER,
    gl.COLOR_ATTACHMENT0,
    gl.TEXTURE_2D,
    tex,
    0
  );
  gl.readPixels(
    0,
    0,
    width,
    height,
    gl[webglConfig.format],
    gl[webglConfig.type],
    0
  );
  gl.bindBuffer(gl.PIXEL_PACK_BUFFER, null);
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  return buffer;
}

export function deleteBuffer(buffer: WebGLBuffer) {
  if (!gl) throw new Error("cannot call deleteBuffer without a WebGL context");
  gl.deleteBuffer(buffer);
}

export function deleteTexture(tex: WebGLTexture) {
  if (!gl) throw new Error("cannot call deleteTexture without a WebGL context");
  gl.deleteTexture(tex);
}

export function glUseProgram(program: WebGLProgram) {
  if (!gl) throw new Error("cannot call useProgram without a WebGL context");
  gl.useProgram(program);
}
