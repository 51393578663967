import * as onnx from "onnxjs";
import { useState, useEffect } from "react";

async function loadModel(
  modelPath: string,
  setSession: (session: onnx.InferenceSession | null) => void
) {
  const session = new onnx.InferenceSession();
  await session.loadModel(modelPath);
  setSession(session);
}

export function useOnnxModel(modelPath: string) {
  const [onnxSession, setOnnxSession] = useState<onnx.InferenceSession | null>(
    null
  );

  useEffect(() => {
    loadModel(modelPath, setOnnxSession);
  }, [modelPath]);

  return onnxSession;
}
