import Sketch from "react-p5";
import p5Types from "p5";

let ball: { x: number; y: number; speedX: number; speedY: number };
function setup(p5: p5Types, canvasParentRef: Element) {
  p5.createCanvas(400, 400).parent(canvasParentRef);
  ball = {
    x: p5.width / 2,
    y: p5.height / 2,
    speedX: p5.random(-5, 5),
    speedY: p5.random(-5, 5),
  };
}

function draw(p5: p5Types) {
  p5.background(0);
  p5.fill(255);
  p5.ellipse(ball.x, ball.y, 50, 50);

  ball.x += ball.speedX;
  ball.y += ball.speedY;

  if (ball.x > p5.width || ball.x < 0) {
    ball.speedX *= -1;
  }
  if (ball.y > p5.height || ball.y < 0) {
    ball.speedY *= -1;
  }
}

export default function SandboxP5js() {
  return (
    <div className="flex justify-center items-center h-screen space-x-12">
      <h1 className="text-4xl text-center text-blue-500 font-bold">
        maicon.site
      </h1>

      <Sketch setup={setup} draw={draw} />
    </div>
  );
}
