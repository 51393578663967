import {
  P5CanvasInstance,
  ReactP5Wrapper,
  Sketch,
  SketchProps,
} from "@p5-wrapper/react";

type Particle = {
  x: number;
  y: number;
  radius: number;
  color: [number, number, number, number]; // [r, g, b, a]
  increasingAlpha: boolean; // when the particle is created, it slowly becomes visible
};

function getWindowSizes(): [number, number] {
  // this function uses visualViewport on addition to window.innerWidth and window.innerHeight
  // because on mobile devices, the window size can change when the address bar is shown or hidden
  // this can lead to a flickering effect on the canvas, so visualViewport is used to get a more stable window size
  return [
    window.visualViewport ? window.visualViewport.width : window.innerWidth,
    window.visualViewport ? window.visualViewport.height : window.innerHeight,
  ];
}

function perlinHue(
  p5: P5CanvasInstance<CombinedSketchProps>,
  x: number,
  y: number,
  noiseScale: number
): number {
  // Particle is created with a HUE values based on the position it was created using perlin noise
  return p5.map(p5.noise(x * noiseScale, y * noiseScale), 0, 1, 0, 360);
}

function createParticle(
  p5: P5CanvasInstance<CombinedSketchProps>,
  createNewParticles: boolean,
  noiseScale: number,
  radiusRange: [number, number],
  brightness: number,
  saturation: number
): Particle | null {
  if (!createNewParticles) {
    return null;
  }
  const x = p5.random(p5.width);
  const y = p5.random(p5.height);
  const radius = p5.random(radiusRange[0], radiusRange[1]);
  p5.colorMode(p5.HSB, 360, 100, 100); // Switch to HSB mode
  const hue = perlinHue(p5, x, y, noiseScale); // From 0 to 360
  const color = p5.color(hue, saturation, brightness);
  const rgb_color: [number, number, number, number] = [
    p5.red(color),
    p5.green(color),
    p5.blue(color),
    0, // Starts invisible
  ];
  p5.colorMode(p5.RGB, 255); // Switch back to RGB mode
  return {
    x,
    y,
    radius,
    color: rgb_color,
    increasingAlpha: true, // When created, the particle starts invisible and slowly becomes visible
  };
}

type CombinedSketchProps = {
  clear: boolean;
  setClear: (clear: boolean) => void;
} & SketchProps;

const sketch: Sketch = (p5: P5CanvasInstance<CombinedSketchProps>) => {
  const particles: (Particle | null)[] = [];
  const numParticles = 5000; // Number of particles simultaneously on the canvas
  const maxAlpha = 50; // Max alpha a particle will reach
  const alphaIncrease = 10; // Alpha increase per frame on newly created particles
  const noiseScale = 0.002; // Spatial noise scale for particle movement
  const minimumRadius = 0.5; // Radius at which a particle will be deleted
  const radiusDecay = 0.99; // Radius decay per frame
  const particleSpeed = 0.5; // Constant particle speed in pixels per frame
  const movementAngleScale = 10; // How much scale the angle got from perlin noise for particle movement
  const particleRadiusRange: [number, number] = [1, 3]; // Min and max radius for particles
  const particleBrightness = 75; // From 0 to 100
  const particleSaturation = 75; // From 0 to 100

  // if the average color (r + g + b) for a sample of pixels in the canvas is below this value, new particles will be created, otherwise they will not
  const targetAvgColor = 200; // from 0 to 765 (255 * 3) -
  let createNewParticles = true; // set to false when the average color is above targetAvgColor
  const avgFrameInterval = 60; // Number of frames between average color calculations, this improves performance
  const avgPixelSkip = 1000; // Sample every avgPixelSkip pixels for average color calculation, this improves performance A LOT at the cost of negligible accuracy

  let [canvasWidth, canvasHeight] = getWindowSizes(); // Initial canvas size, using visualViewport for mobile devices
  canvasHeight += 100; // This was added because safari on iOS updates the window height too often and can lead to a flickering effect
  // So 100 pixels are added to the height as a render buffer on all devices, even devices with stable window height

  // external props:
  let clear: boolean = false;
  let setClear: null | ((clear: boolean) => void) = null;

  const createParticleWrapper = () =>
    createParticle(
      p5,
      createNewParticles,
      noiseScale,
      particleRadiusRange,
      particleBrightness,
      particleSaturation
    );

  p5.setup = () => {
    p5.createCanvas(canvasWidth, canvasHeight);
    p5.noStroke();

    // Create initial particles
    for (let i = 0; i < numParticles; i++) {
      particles.push(createParticleWrapper());
    }
  };

  p5.updateWithProps = (props) => {
    if (props.clear !== undefined) {
      clear = props.clear;
    }
    if (props.setClear !== undefined) {
      setClear = props.setClear;
    }
  };

  p5.draw = () => {
    // Clear canvas if needed
    if (clear === true && setClear !== null) {
      // Clear canvas
      p5.clear(0, 0, 0, 255);
      setClear(false);
      // Set new seed for perlin noise
      p5.noiseSeed(p5.random(100000));
      // Reset particles
      for (let i = 0; i < numParticles; i++) {
        particles[i] = createParticleWrapper();
      }
    }

    // Calculate average color of the canvas, if it's below targetAvgColor, set flag to create new particles to true, otherwise set it to false
    if (p5.frameCount % avgFrameInterval === 0) {
      p5.loadPixels();
      let totalColor = 0;
      let totalPixels = 0;
      for (let i = 0; i < p5.pixels.length; i += 4 * avgPixelSkip) {
        totalColor += p5.pixels[i] + p5.pixels[i + 1] + p5.pixels[i + 2];
        totalPixels += 1;
      }
      const avgColor = totalColor / totalPixels;
      console.log(avgColor);
      if (avgColor < targetAvgColor) {
        createNewParticles = true;
      } else {
        createNewParticles = false;
      }
    }

    // Simulate particles
    for (let i = 0; i < numParticles; i++) {
      // Logic to create new particles only when needed
      const particle = particles[i];
      if (particle === null && createNewParticles) {
        particles[i] = createParticleWrapper();
      }
      if (particle === null) {
        continue;
      }

      // Draw particle
      p5.fill(
        particle.color[0],
        particle.color[1],
        particle.color[2],
        particle.color[3]
      );
      p5.ellipse(particle.x, particle.y, particle.radius);

      // Particle moves according to spatial perlin noise
      const x = particle.x;
      const y = particle.y;
      const noiseValue = p5.noise(
        x * noiseScale + 10000,
        y * noiseScale + 100000
      );
      const angle = p5.map(
        noiseValue,
        0,
        1,
        0,
        2 * Math.PI * movementAngleScale
      );
      particle.x += Math.cos(angle) * particleSpeed;
      particle.y += Math.sin(angle) * particleSpeed;

      // Delete particle if it's too small
      particle.radius *= radiusDecay;
      if (particle.radius < minimumRadius) {
        particles[i] = createParticleWrapper();
      }

      // Particle slowly becomes visible when created
      if (particle.increasingAlpha) {
        particle.color[3] += alphaIncrease;
      }
      if (particle.color[3] > maxAlpha) {
        particle.increasingAlpha = false;
        particle.color[3] = maxAlpha;
      }

      // If particle is 50 pixels outside the canvas, delete it
      if (
        particle.x < -50 ||
        particle.x > p5.width + 50 ||
        particle.y < -50 ||
        particle.y > p5.height + 50
      ) {
        particles[i] = createParticleWrapper();
      }
    }
  };

  p5.windowResized = () => {
    // Resize p5js canvas only when window size increases to prevent flickering, if it decreases,
    // then the background will have a dead zone, but it's better than flickering
    const [width, height] = getWindowSizes();
    if (width > canvasWidth || height > canvasHeight) {
      p5.resizeCanvas(Math.floor(width), Math.floor(height));
      canvasWidth = width;
      canvasHeight = height;
    }
  };
};

export default function PerlinFlowBackground({
  clear,
  setClear,
}: {
  clear: boolean;
  setClear: (clear: boolean) => void;
}) {
  return <ReactP5Wrapper sketch={sketch} clear={clear} setClear={setClear} />;
}
