import {
  P5CanvasInstance,
  ReactP5Wrapper,
  Sketch,
  SketchProps,
} from "@p5-wrapper/react";
import { findMinAndMax2d } from "../utils/listUtils";

type CombinedSketchProps = {
  matrix: number[][] | null;
  canvasSize?: [number, number]; // [width, height]
  minMax?: [number, number];
} & SketchProps;

const sketch: Sketch = (p5: P5CanvasInstance<CombinedSketchProps>) => {
  let matrix: number[][] | null = null;
  let matrixUpdateCount: number = 0;
  let matrixRenderCount: number = 0;
  let canvasSize: [number, number] | null = null;
  let externalMinMax: [number, number] | null = null;

  p5.updateWithProps = (props) => {
    if (props.matrix) {
      matrix = props.matrix;
      matrixUpdateCount++;
    }
    if (props.canvasSize) {
      canvasSize = props.canvasSize;
      p5.resizeCanvas(canvasSize[0], canvasSize[1]);
    }
    if (props.minMax) {
      externalMinMax = props.minMax;
    }
  };

  p5.setup = () => {
    p5.createCanvas(100, 100);
    p5.noStroke();
  };

  p5.draw = () => {
    if (matrix === null) {
      p5.background(255, 0, 0);
      return;
    }

    if (matrixUpdateCount === matrixRenderCount) {
      return;
    }
    matrixRenderCount = matrixUpdateCount;
    const pixelWidth = p5.width / matrix[0].length;
    const pixelHeight = p5.height / matrix.length;

    let min: number, max: number;
    if (externalMinMax) {
      [min, max] = externalMinMax;
    } else {
      [min, max] = findMinAndMax2d(matrix);
    }

    p5.background(0);
    for (let i = 0; i < matrix[0].length; i++) {
      for (let j = 0; j < matrix.length; j++) {
        const value = matrix[j][i];
        // const value = j / 100 + i / 100;
        const color = p5.map(value, min, max, 0, 255);
        p5.fill(color);
        p5.rect(
          i * pixelWidth,
          j * pixelHeight,
          pixelWidth * 1.1,
          pixelHeight * 1.1
        );
      }
    }
  };
};

export default function Matrix({
  matrix,
  canvasSize,
  minMax,
}: {
  matrix: number[][] | null;
  canvasSize?: [number, number]; // [width, height]
  minMax?: [number, number];
}) {
  return (
    <ReactP5Wrapper
      sketch={sketch}
      matrix={matrix}
      canvasSize={canvasSize}
      minMax={minMax}
    />
  );
}
