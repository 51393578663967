export function interpolate(a: number, b: number, weight: number) {
  return a * (1 - weight) + b * weight;
}

export function ChannelsGrid({
  isMobile,
  mobileTemplate,
  desktopTemplate,
  length,
  setRef,
  width,
  height,
  children,
}: {
  isMobile: boolean;
  mobileTemplate: string;
  desktopTemplate: string;
  length: number;
  setRef: (el: HTMLCanvasElement | null, i: number) => void;
  width: number;
  height: number;
  children: React.ReactNode;
}) {
  return (
    <>
      <p>{children}</p>
      <div
        className="grid"
        style={{
          gridTemplateColumns: isMobile ? mobileTemplate : desktopTemplate,
          gap: "2px",
        }}
      >
        {Array.from({ length }).map((_, i) => (
          <canvas
            ref={(el) => setRef(el, i)}
            key={i}
            width={width}
            height={height}
          ></canvas>
        ))}
      </div>
    </>
  );
}

export function Canvas({
  setRef,
  width,
  height,
  children,
}: {
  setRef: (el: HTMLCanvasElement | null) => void;
  width: number;
  height: number;
  children?: React.ReactNode;
}) {
  return (
    <div>
      {children && <p>{children}</p>}
      <canvas ref={setRef} width={width} height={height}></canvas>
    </div>
  );
}

export function isInteger(value: number) {
  return value === Math.floor(value);
}
