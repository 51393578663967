import {
  P5CanvasInstance,
  ReactP5Wrapper,
  Sketch,
  SketchProps,
} from "@p5-wrapper/react";
import { useState } from "react";
import { isPointWithinRadiusOfLine, mouseInCanvas } from "../utils/p5jsUtils";
import { downsampleArray2d, toGrayscale } from "../utils/listUtils";
import Button from "./Button";

type CombinedSketchProps = {
  clear: boolean;
  setClear: ((clear: boolean) => void) | null;
  setImage: ((img: number[][]) => void) | null;
  outputSize: [number, number] | null;
  canvasSize: [number, number];
  pixelSize: number;
  strokeSize: number;
  drawableCanvasImage: number[][][] | null;
  setDrawableCanvasImage: ((img: number[][][] | null) => void) | null;
} & SketchProps;

const sketch: Sketch = (p5: P5CanvasInstance<CombinedSketchProps>) => {
  let clear: boolean = false;
  let setClear: ((clear: boolean) => void) | null = null;
  let setImage: ((img: number[][]) => void) | null = null;
  let outputSize: [number, number] | null = null;
  let pixelSize: number | null = null;
  let strokeSize: number | null = null;
  let setDrawableCanvasImage: ((img: number[][][] | null) => void) | null =
    null;
  let canvasSize: [number, number] = [100, 100];

  let pixels: number[][] | null = null;
  let pixelChangeCount: number = 0;
  let pixelRenderCount: number = 0;

  const resetPixels = () => {
    if (pixelSize === null) return;
    pixels = [];
    for (let i = 0; i < Math.ceil(canvasSize[0] / pixelSize); i++) {
      pixels.push([]);
      for (let j = 0; j < Math.ceil(canvasSize[1] / pixelSize); j++) {
        pixels[i].push(0);
      }
    }
    pixelChangeCount++;
  };

  p5.updateWithProps = (props) => {
    if (props.clear !== undefined) {
      clear = props.clear;
    }
    if (props.setClear !== undefined) {
      setClear = props.setClear;
    }
    if (props.setImage !== undefined) {
      setImage = props.setImage;
    }
    if (props.outputSize !== undefined) {
      outputSize = props.outputSize;
    }
    if (props.canvasSize !== undefined) {
      canvasSize = props.canvasSize;
    }
    if (props.pixelSize !== undefined) {
      // assert pixelSize is integer
      if (props.pixelSize % 1 !== 0) {
        throw new Error("pixelSize must be an integer");
      }
      pixelSize = props.pixelSize;
    }
    if (props.strokeSize !== undefined) {
      // assert strokeSize is integer
      if (props.strokeSize % 1 !== 0) {
        throw new Error("strokeSize must be an integer");
      }
      strokeSize = props.strokeSize;
    }
    if (props.setDrawableCanvasImage !== undefined) {
      setDrawableCanvasImage = props.setDrawableCanvasImage;
    }
    if (
      props.drawableCanvasImage !== null &&
      setDrawableCanvasImage !== null &&
      pixelSize !== null
    ) {
      const image = props.drawableCanvasImage;
      const grayScaledImage = toGrayscale(image);

      const resizedImage = downsampleArray2d(
        grayScaledImage,
        Math.ceil(canvasSize[0] / pixelSize),
        Math.ceil(canvasSize[1] / pixelSize)
      );
      pixels = resizedImage;
      setDrawableCanvasImage(null);
      pixelChangeCount++;
    }
  };

  p5.setup = () => {
    const canvas = p5.createCanvas(1, 1);
    p5.background(0);
    p5.noStroke();

    // prevent scrolling on touch devices
    canvas.elt.addEventListener(
      "touchmove",
      (e: TouchEvent) => {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  p5.draw = () => {
    if (pixelSize === null || strokeSize === null) return;

    // update canvas size when it changes
    if (canvasSize[0] !== p5.width || canvasSize[1] !== p5.height) {
      p5.resizeCanvas(canvasSize[0], canvasSize[1]);
      pixelChangeCount++; // force redraw
    }

    // draw and save the pixels if they have changed
    if (
      pixels !== null &&
      pixelChangeCount !== pixelRenderCount &&
      outputSize !== null &&
      setImage !== null
    ) {
      pixelRenderCount = pixelChangeCount;
      for (let i = 0; i < pixels.length; i++) {
        for (let j = 0; j < pixels[i].length; j++) {
          p5.fill(pixels[j][i]);
          p5.rect(i * pixelSize, j * pixelSize, pixelSize, pixelSize);
        }
      }

      p5.fill(255);
      p5.text(p5.frameRate().toFixed() + " fps", 10, 10);
      // send pixels to parent component
      const resizedPixels = downsampleArray2d(
        pixels,
        outputSize[0],
        outputSize[1]
      );
      setImage(resizedPixels);
    }

    // draw white line from previous mouse position to current mouse position when mouse is pressed
    if (p5.mouseIsPressed && mouseInCanvas(p5) && pixels !== null) {
      pixelChangeCount++;
      const x1 = p5.mouseX;
      const y1 = p5.mouseY;
      const x2 = p5.pmouseX;
      const y2 = p5.pmouseY;
      for (let i = 0; i < Math.ceil(canvasSize[0] / pixelSize); i++) {
        for (let j = 0; j < Math.ceil(canvasSize[1] / pixelSize); j++) {
          const scaledI = Math.floor(i * pixelSize);
          const scaledJ = Math.floor(j * pixelSize);
          if (
            isPointWithinRadiusOfLine(
              x1,
              y1,
              x2,
              y2,
              scaledI,
              scaledJ,
              strokeSize
            )
          ) {
            pixels[j][i] = 255;
          }
        }
      }
    }

    // clear the pixels if the clear flag is set
    if (clear && setClear !== null && pixels !== null) {
      resetPixels();
      setClear(false);
    }
  };
};

export default function DrawableCanvas({
  setImage,
  outputSize,
  canvasSize,
  pixelSize,
  strokeSize,
  drawableCanvasImage,
  setDrawableCanvasImage,
}: {
  setImage: (img: number[][]) => void; // this will set the external image with resized pixels
  outputSize: [number, number];
  canvasSize: [number, number];
  pixelSize: number;
  strokeSize: number;
  drawableCanvasImage: number[][][] | null; // this is for when the external component wants to set the internal image of the drawable canvas
  setDrawableCanvasImage: (img: number[][][] | null) => void; // drawable canvas will clear the external variable after setting the image
}) {
  const [clear, setClear] = useState<boolean>(false);

  return (
    <div className="flex flex-col space-y-2">
      <ReactP5Wrapper
        sketch={sketch}
        clear={clear}
        setClear={setClear}
        setImage={setImage}
        outputSize={outputSize}
        canvasSize={canvasSize}
        pixelSize={pixelSize}
        strokeSize={strokeSize}
        drawableCanvasImage={drawableCanvasImage}
        setDrawableCanvasImage={setDrawableCanvasImage}
      />
      <Button
        onClick={() => {
          setClear(true);
        }}
      >
        Clear
      </Button>
    </div>
  );
}
